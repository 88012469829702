import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/offers',
        children: [
            {
                path: '',
                name: 'web-offers.display',
                component: () => import("@components/_frontend/web-offers/Display.vue"),
            },
        ]
    },
    {
        path: '/',
        children: [
            {
                path: '',
                name: 'web-offers.homepage-display',
                component: () => import("@components/_frontend/web-offers/Display.vue"),
            },
        ]
    },
    {
        path: '/jobs',
        children: [
            {
                path: '',
                name: 'job-posts.display',
                component: () => import("@components/_frontend/job-posts/Display.vue"),
            },
        ]
    }
]

let scrollPosition = Object.create(null);
let samePage = null;

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (!scrollPosition.top) {
            scrollPosition = savedPosition || {left: 0, top: 0};
        }

        if (samePage) {
            return;
        }

        // Return a promise to wait for the page to finish loading
        return new Promise((resolve) => {
            if (document.readyState === 'complete') {
                // If the page is already loaded, scroll immediately
                scroll();
            } else {
                // If the page is still loading, wait for it to finish
                window.addEventListener('load', scroll);
            }

            function scroll() {
                window.requestAnimationFrame(() => {
                    window.scroll({
                        top: scrollPosition.top,
                        left: scrollPosition.left,
                        behavior: 'smooth',
                    });
                    resolve();
                });
            }
        });
    },
});

router.beforeEach((to, from) => {
    samePage = to.name === from.name;
});

router.afterEach((to, from) => {
    scrollPosition = Object.create(null);
});

export default router;
