import {createApp} from "vue";
import {createPinia} from 'pinia';
import axios from 'axios';
import App from "@components/_frontend/FrontendApp.vue";
import frontendAppRoutes from "./router/frontend-app";
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import {createI18n} from 'vue-i18n';
import messages from "@/js/locales/messages";

const i18n = createI18n({
	locale: 'ro',
	fallbackLocale: ['ro', 'en'],
	messages: messages,
})

axios.defaults.baseURL = '/api/v1.0/'
axios.defaults.withCredentials = true

const toastOptions = {}
const pinia = createPinia()

createApp(App)
	.use(frontendAppRoutes)
	.use(pinia)
	.use(Toast, toastOptions)
	.use(i18n)
	.mount('#frontend-app');
